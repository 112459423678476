<template>
	<div class="operate">
		<Slide></Slide>
		<div class="operate-wrapper">
			<keep-alive >
				<router-view></router-view>
			</keep-alive>
		</div>
	</div>
</template>

<script type="text/javascript">
import Slide from '@/components/slide'
export default {
	data() {
		return {

		}
	},
	components: {
		Slide
	},
	methods: {

	},
	mounted() {

	}
}
</script>

<style lang="less">
.operate {
	height: calc(100vh - 64px);
	display: flex;
	&-wrapper {
		flex: 1;
		overflow: hidden;
	}
}
</style>