<template>
	<div>
		<div class="tabbar">
			<div
				class="tabbar-item bg"
				:class="[path == item.path ? 'sel' : '']"
				v-for="(item, index) in list"
				:key="index"
				:style="{ backgroundImage: `url(${path == item.path ? item.selIcon : item.icon})` }"
				@click="clickTab(item)"
			>
				{{ item.name }}
			</div>
		</div>
		<div class="tabber-place"></div>
	</div>
</template>

<script type="text/javascript">
export default {
	data() {
		return {
			list: [{
				name: '首页',
				path: '/',
				icon: require('../assets/image/home.png'),
				selIcon: require('../assets/image/homeSel.png'),
			}, {
				name: '历史记录',
				path: '/history',
				icon: require('../assets/image/task.png'),
				selIcon: require('../assets/image/taskSel.png'),
			}, {
				name: '免费次数',
				path: '/fee',
				icon: require('../assets/image/yaoqing.png'),
				selIcon: require('../assets/image/yaoqingSel.png'),
			}, {
				name: '我的',
				path: '/mine',
				icon: require('../assets/image/me.png'),
				selIcon: require('../assets/image/meSel.png'),
			}]
		}
	},
	computed: {
		path() {
			return this.$route.path
		}
	},
	methods: {
		clickTab(item) {
			this.$router.push(item.path)
		}
	},
	mounted() {
	}
}
</script>

<style lang="less" scoped>
.tabbar {
	width: 7.5rem;
	height: 1.6rem;
	background: #ffffff;
	box-shadow: 0 -0.04rem 0.1rem 0 rgba(0, 0, 0, 0.04);
	display: flex;
	box-sizing: border-box;
	padding-top: 0.23rem;
	justify-content: space-around;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 1036;
	&-item {
		font-size: 0.22rem;
		font-weight: 400;
		color: #7f8389;
		line-height: 0.30rem;
		text-align: center;
		padding-top: 0.5rem;
		flex: 1;
		background-size: 0.46rem;
		background-position: center top;
		&.sel {
			color: #23B1FF;
		}
	}
}
.tabber-place {
	height: 1.6rem;
}
</style>
