import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/style/reset.less';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/theme/index.css';
import ElementUi from 'element-ui';
import tButton from '@/components/tButton'
import VueClipboard from 'vue-clipboard2'
import tryTime from '@/components/tryTime'
import filter from '@/common/filter'
import Mixin from '@/common/mixin'
import VueParticles from 'vue-particles';

import 'vant/lib/popup/style';
import 'vant/lib/toast/style';
import 'vant/lib/dialog/style';
 
Vue.use(ElementUi);
Vue.use(VueParticles);
Vue.use(VueClipboard)
Vue.use(filter)
Vue.mixin(Mixin)
Vue.config.productionTip = false;

Vue.component('t-button', tButton)
Vue.component('try-time', tryTime)

Vue.directive('loadmore', {
	bind(el, binding) {
		const selectDom = el;
		selectDom.addEventListener('scroll', function() {
			const condition =  selectDom.scrollHeight - selectDom.scrollTop <= selectDom.clientHeight;
			if (condition) {
				binding.value()
			}
		})
	}
})

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
