<template>
	<div 
		class="slide "
		:class="[openSlide ? '' : 'min']"
	>
		<div class="slide-title">
			工作台
			<img 
				class="hover" 
				src="https://i.gsxcdn.com/2121283515_wpy34clp.png" alt=""
				@click="clickToggle"
			>
		</div>
		<!-- <div class="slide-list hover">
			<span class="slide-list__icon task bg"></span>
			<span>班级任务</span>
		</div> -->

		<div class="slide-label">教学工具</div>
		<div 
			class="slide-list hover"
			:class="[curLight == 4 ? 'sel' : '']"
			@click="goPage('correct')"
		>
			<span class="slide-list__icon ai bg"></span>
			<span>AI作文批改</span>
		</div>
		<div class="slide-label">备课专区</div>

		<div 
			class="slide-list hover"
			:class="[curLight == 2 ? 'sel' : '']"
			@click="goPage('official')"
		>
			<span class="slide-list__icon official bg"></span>
			<span>官方资源库</span>
		</div>
		<div 
			class="slide-list hover"
			:class="[curLight == 3 ? 'sel' : '']"
			@click="goPage('mine')"
		>
			<span class="slide-list__icon my bg"></span>
			<span>我的网盘</span>
		</div>

		

		<!-- <div class="slide-label">账号管理</div>
		<div class="slide-list hover" @click="goOut">
			<span class="slide-list__icon logout bg"></span>
			<span>退出登录</span>
		</div> -->
	</div>
</template>

<script type="text/javascript">
import {
	mapState
} from 'vuex'
export default {
	data() {
		return {};
	},
	computed: {
		...mapState(['openSlide']),
		curLight() {
			const {path} = this.$route;

			if (path.indexOf('correct') !== -1) {
				return 4
			}
			if (path.indexOf('mine') !== -1) {
				return 3
			}
			if (path.indexOf('official') !== -1) {
				return 2
			}
			if (path.indexOf('task') !== -1) {
				return 1
			}
			return 0
		}
	},
	methods: {
		goPage(name) {
			this.$router.push({
				name
			})
		},
		goOut() {
			this.$store.commit('updateToken', '');
			this.$router.push('/login');
		},
		clickToggle() {
			this.$store.commit('updateOpenSlide')
		}
	},
	mounted() {
	}
};
</script>

<style lang="less">
.slide {
	width: 180px;
	flex: 0 0 180px;
	background: white;
	box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.04);
	transition: all 0.2s ease-in-out;
	&.min {
		width: 76px;
		flex: 0 0 76px;
		.slide-title {
			height: 88px;
			font-size: 16px;
			line-height: 22px;
			text-align: center;
			padding-top: 66px;
			padding-left: 0;
			img {
				right: 26px;
				transform: rotate(180deg);
			}
		}
		.slide-label {
			height: 34px;
			line-height: 34px;
			text-indent: 0;
			text-align: center;
			font-size: 12px;
		}
		.slide-list {
			padding: 8px 0;
			height: auto;
			flex-direction: column;
			padding-left: 0;
			justify-content: center;
			font-size: 12px;
		}
		.slide-list__icon {
			margin-right: 0;
			margin-bottom: 5px;
		}
	}
	&-title {
		height: 60px;
		font-size: 20px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #39b0fc;
		line-height: 28px;
		padding-left: 21px;
		padding-top: 24px;
		box-sizing: border-box;
		white-space: nowrap;
		background: linear-gradient(
			180deg,
			rgba(187, 228, 255, 0.41) 0%,
			#ffffff 100%
		);
		margin-bottom: 10px;
		position: relative;
		img {
			width: 22px;
			height: 22px;
			position: absolute;
			right: 14px;
			top: 25px;
		}
	}
	&-label {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #9ca0a8;
		line-height: 20px;
		letter-spacing: 1px;
		height: 50px;
		line-height: 50px;
		text-indent: 16px;
	}
	&-list {
		height: 50px;
		display: flex;
		align-items: center;
		padding-left: 18px;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #585a6c;
		line-height: 20px;
		letter-spacing: 1px;
		&:hover {
			background: #F7F8FA;
		}
		&.sel {
			color: #39B0FC;
			background: #E6F5FF;
			.slide-list__icon {
				&.task {
					background-image: url(https://i.gsxcdn.com/2120465765_jpm67fyf.png);
				}
				&.official {
					background-image: url(https://i.gsxcdn.com/2120465766_acxv3wx9.png);
				}
				&.my {
					background-image: url(https://i.gsxcdn.com/2133230813_fftxe6tj.png);
				}
				&.ai {
					background-image: url(https://i.gsxcdn.com/2120465764_fvfyzv68.png);
				}
				&.logout {
					background-image: url(https://i.gsxcdn.com/2120465767_t2nyy0nx.png);
				}
			}
		}
		&__icon {
			width: 22px;
			height: 22px;
			margin-right: 12px;
			&.task {
				background-image: url(https://i.gsxcdn.com/2120460538_qdz0pyem.png);
			}
			&.official {
				background-image: url(https://i.gsxcdn.com/2120460539_j9oi8zae.png);
			}
			&.my {
				background-image: url(https://i.gsxcdn.com/2133230811_5sux7xgo.png);
			}
			&.ai {
				background-image: url(https://i.gsxcdn.com/2120460537_9fhf926d.png);
			}
			&.logout {
				background-image: url(https://i.gsxcdn.com/2120460541_j9ml1mqg.png);
			}
		}
	}
}
</style>
