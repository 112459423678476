<template>
	<div
		class="try-time fcc"
		v-if="showTryTime || isPhone"
		:class="[isPhone ? 'phone' : '']"
	>
		<div class="try-time__content bg">
			<div class="try-time__content--close bg hover" @click="close"></div>
			<div class="try-time__content--title">
				{{ tryTitle }}
				<p>每邀请1人，免费次数+10</p>
			</div>
			<div class="try-time__content--box">
				<div class="box-left">
					<div class="box-title">{{isPhone ? '' : '我的'}}专属邀请链接</div>
					<div class="box-link">
						{{ linkDesc }}
						<p class="ellipsis">{{ showShareLink }}
							<span 
								class="mobile-button"
								v-if="isPhone"
								@click="copyLink"
							>复制链接</span>
						</p>

					</div>
					<t-button 
						class="left-button" 
						@click="copyLink"
						v-if="!isPhone"
					>一键复制</t-button>
				</div>
				<div class="box-right fyc" v-if="!isPhone">
					<div class="box-title">{{isPhone ? '分享' : '我的专属'}}邀请海报</div>
					<div class="box-image bg">
						<div
							id="qr-code"
							class="bg"
							:style="{ backgroundImage: `url(${qrCodeUrl})` }"
						>	
						</div>
					</div>
					<t-button class="box-download" @click="downloadImage"
						>下载</t-button
					>
				</div>
			</div>
			<div class="try-time__content--tips">
				邀请详情：<br />
				首次加入可直接获得20次免费提问机会，30日有效；另外，按照上方任意一种方式<br />
				邀请同事体验，每邀请1人，免费次数+10，账户所有提问次数有效期往后顺延30日。
			</div>
		</div>
		<div class="image-wrapper bg" ref="imageWrapper">
			<div
				class="code bg"
				:style="{ backgroundImage: `url(${qrCodeUrl})` }"
			></div>
		</div>
	</div>
</template>

<script type="text/javascript">
import { mapState } from 'vuex';
import { getSortLink } from '@/api';
import qrcode from 'qrcode';
import html2canvas from 'html2canvas';
import Toast from 'vant/lib/toast';
export default {
	data() {
		return {
			linkDesc: '免费送你一个AI教师小管家，一键搞定教案、评语、汇报文稿等！',
			showShareLink: '',
			qrCodeUrl: ''
		};
	},
	watch: {
		shareLink: {
			handler() {
				this.getSort();
			},
			immediate: true
		}
	},
	computed: {
		...mapState(['showTryTime', 'tryTitle', 'userInfo', 'isPhone']),
		shareLink() {
			return window.location.origin + `/login?id=${this.userInfo.id}`;
		}
	},
	methods: {
		getSort() {
			getSortLink({
				url: this.shareLink
			}).then((res) => {
				this.showShareLink = res.data;

				qrcode.toDataURL(this.showShareLink).then((url) => {
					this.qrCodeUrl = url;
				});
			});
		},
		close() {
			this.$store.commit('toggleTry');
		},

		copyLink() {
			this.$copyText(
				`${this.linkDesc}
${this.showShareLink}`
			).then((res) => {
				this.$alert.success('复制成功！');
			});
		},
		downloadImage() {
			html2canvas(this.$refs.imageWrapper).then((canvas) => {
				this.imageUrl = canvas.toDataURL('image/png');
				if (this.imageUrl !== '') {
					const a = document.createElement('a');
					var e = new MouseEvent('click');
					a.download = '邀请老师分享海报';
					a.href = this.imageUrl;
					a.dispatchEvent(e);
				}
			});
		}
	},
	mounted() {
		this.$alert = this.isPhone ? Toast : this.$message
	}
};
</script>

<style lang="less">
.try-time {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 1024;
	&.phone {
		position: relative;
		background: transparent;
		.box-title {
			font-size: 0.36rem;
			padding-top: 0.5rem;
			text-align: left;
			position: relative;
			display: flex;
			align-items: center;
			&:before {
				content: '';
				display: inline-block;
				width: 0.08rem;
				height: 0.36rem;
				background: #39B0FC;
				border-radius: 0.04rem;
				margin-right: 0.16rem;
			}
		}
		.try-time__content--box .box-left {
			flex: auto;
			width: auto;
			display: block;
			margin-right: 0;
			margin-left: 0.42rem;
		}	

		
		.box-link {
			background: transparent;
			padding: 0;
			line-height: 0.42rem;
			font-size: 0.3rem;
			border-radius: 0.12rem;
			.ellipsis {
				width: 6.04rem;
				height: 1.00rem;
				line-height: 1.00rem;
				border-radius: 0.12rem;
				border: 0.02rem solid #E0E2E6;
				position: relative;
				box-sizing: border-box;
				padding-right: 1.7rem;
				.mobile-button {
					width: 1.77rem;
					height: 1rem;
					text-align: center;
					font-size: 0.32rem;
					border: 0.02rem solid #39B0FC;
					color: white;
					line-height: 1rem;
					background: #39B0FC;
					border-radius: 0 0.12rem 0.12rem 0;
					position: absolute;
					right: -0.02rem;
					top: -0.02rem;
				}
			}
		}
		.try-time__content {
			width: auto;
			background: transparent;
		}
		.try-time__content--title {
			font-size: 0.4rem;
			p {
				font-size: 0.32rem;
			}
		}
		.try-time__content--box .box-link p {
			margin-top: 0.32rem;
			padding-left: 0.26rem;
		}
		.try-time__content--close {
			display: none;
		}
		.try-time__content--box {
			// display: flex;
			// flex-direction: column;
			// align-items: center;
			margin: 0.5rem 0.32rem 0;
			background: #FFFFFF;
			box-shadow: 0 0 0.10rem 0.02rem rgba(0,0,0,0.04);
			border-radius: 0.24rem;
		}
		.try-time__content--tips {
			font-size: 0.24rem;
			font-weight: 400;
			color: #707281;
			line-height: 0.38rem;
			padding: 0.5rem 0.32rem 1.8rem;
			background: #f8fafb;

		}
		.image-wrapper {
			position: fixed;
			left: -100%;
			top: 0;
			width: 7rem;
			height: 7rem;
			background-image: url(../assets/image/poster.jpg);
			z-index: 3;
			.code {
				width: 1.56rem;
				height: 1.56rem;
				position: absolute;
				right: 0.9rem;
				bottom: 0.74rem;
				border-radius: 0.08rem;
			}
		}
	}

	.image-wrapper {
		position: absolute;
		left: -100%;
		top: 0;
		width: 500px;
		height: 500px;
		background-image: url(../assets/image/poster.jpg);
		z-index: -1;
		.code {
			width: 112px;
			height: 112px;
			position: absolute;
			right: 64px;
			bottom: 53px;
			border-radius: 10px;
		}
	}
	#qr-code {
		width: 30px;
		height: 30px;
		position: absolute;
		right: 17px;
		bottom: 14px;
	}
	&__content {
		width: 649px;
		height: 553px;
		background-color: #ffffff;
		border-radius: 16px;
		position: relative;
		&--close {
			width: 28px;
			height: 28px;
			position: absolute;
			right: 14px;
			top: 14px;
			background-image: url('../assets/image/close-dialog.png');
		}
		&--tips {
			font-size: 14px;
			font-weight: 400;
			color: #707281;
			line-height: 28px;
			padding-left: 84px;
		}
		&--title {
			margin-top: 50px;
			text-align: center;
			font-size: 18px;
			font-weight: 500;
			color: #11142d;
			line-height: 32px;
			p {
				color: #ff5725;
			}
		}
		&--box {
			margin-top: 34px;
			margin: 34px 75px 28px 60px;
			display: flex;
			.box-left {
				flex: 0 0 286px;
				width: 286px;
				margin-right: 27px;
			}
			.box-title {
				font-size: 16px;
				font-weight: 400;
				color: #11142d;
				line-height: 28px;
				margin-bottom: 16px;
				text-align: center;
			}
			.box-link {
				width: 286px;
				height: 134px;
				background: #f8fafb;
				border-radius: 8px;
				box-sizing: border-box;
				padding: 15px 38px 0 18px;
				font-size: 14px;
				font-weight: 400;
				color: #585a6c;
				line-height: 22px;
				p {
					width: 250px;
					height: 50px;
					border-radius: 8px;
					border: 2px solid #e0e2e6;
					margin-top: 11px;
					line-height: 46px;
					padding: 0 16px;
					box-sizing: border-box;
				}
			}
			.left-button {
				margin: 20px auto 0;
			}

			.box-right {
				flex: 0 0 200px;
				width: 200px;
				flex-direction: column;
			}
			.box-image {
				width: 134px;
				height: 134px;
				border-radius: 8px;
				background-image: url(../assets/image/poster.jpg);
				position: relative;
			}
			.box-download {
				margin-top: 20px;
			}
		}
	}
}
</style>
