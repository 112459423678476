<template>
	<div class="navigation-box">
		<div class="navigation-place"></div>
		<div class="navigation">
			
			<div class="navigation-title" @click="goHome">
				<img
					src="https://i.gsxcdn.com/2159095777_zvv6n2rq.png"
					class="navigation-logo hover"
					@click="goHome"
				/>
			</div>
			<div class="navigation-share hover" @click="feedback" v-if="pathName != 'feedback'">
				<img
					class="navigation-share__icon"
					src="https://i.gsxcdn.com/2109851442_jb09wl6g.png"
					alt=""
				/>
				提提建议
			</div>


			<el-dropdown placement="bottom-start" @command="clickSubject" v-if="userInfo.subject_type == 4">
				<div class="navigation-subject hover">
					{{userInfo.subject_id == 1 ? '语文' : '英语'}}
					<img src="../assets/image/down.png" alt="" />
				</div>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item :command="1">
						语文
					</el-dropdown-item>
					<el-dropdown-item :command="3"
						>英语</el-dropdown-item
					>
				</el-dropdown-menu>
			</el-dropdown>
			<div class="navigation-subject hover" v-else>
				{{userInfo.subject_id == 1 ? '语文' : '英语'}}
			</div>

			

			<el-dropdown placement="bottom-start" @command="handleClick">
				<div class="navigation-user">
					<div
						class="navigation-user__header bg"
						:style="{
							backgroundImage: `url(${userInfo.avatarurl})`
						}"
					></div>
					<div class="navigation-user__name bg hover">
						{{ userInfo.real_name || userInfo.nickname }}
					</div>
				</div>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="id">
						用户ID：{{ userInfo.id }}
						<i class="el-icon-document-copy"></i>
					</el-dropdown-item>
					<el-dropdown-item command="logout"
						>退出登录</el-dropdown-item
					>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script type="text/javascript">
import { mapState } from 'vuex';
export default {
	data() {
		return {};
	},
	computed: {
		...mapState(['userInfo']),
		pathName() {
			return this.$route.name
		}
	},
	methods: {
		goHome() {
			this.$router.push('/');
		},
		goMine() {
			this.$router.push('/mine');
		},
		feedback() {
			this.$store.commit('updatePrevPathName', this.$route.name)
			
			this.$nextTick(() => {
				this.$router.push('/feedback');
			})
		},
		handleClick(e) {
			if (e == 'logout') {
				this.$store.commit('updateToken', '');
				this.$router.push('/login');
			}
			if (e == 'id') {
				this.$copyText(this.userInfo.id + '').then((e) => {
					this.$message.success('复制成功！');
				});
			}
		},
		clickSubject(e) {

			this.userInfo.subject_id = e

			this.$store.commit('updateUserInfo', this.userInfo)
		}
	},
	mounted() {
		console.log(this.path)
	}
};
</script>

<style lang="less" scoped>
.navigation-box {
}
.navigation-place {
	width: 100%;
	height: 64px;
}
.navigation {
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1024;
	height: 64px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e4e4e4;
	background: white;
	&-subject {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #212d44;
		line-height: 20px;
		margin-right: 35px;
		img {
			width: 18px;
			height: 18px;
			margin-left: 4px;
		}
	}
	&-user {
		display: flex;
		align-items: center;
		&__header {
			width: 22px;
			height: 22px;
			border-radius: 50%;
			border: 2px solid #f2f2f2;
			margin-right: 8px;
		}
		&__name {
			font-size: 14px;
			font-weight: 400;
			color: #212d44;
			line-height: 22px;
			margin-right: 60px;
			padding-right: 20px;
			background-image: url(../assets/image/down.png);
			background-size: 18px;
			background-position: right center;
		}
	}
	&-logo {
		height: 30px;
		width: 159px;
		margin-left: 30px;
		margin-right: 12px;
	}
	&-title {
		flex: 1;

		&__name {
			font-size: 16px;
			font-weight: 400;
			color: #11142d;
			line-height: 14px;
		}
		&__slogen {
			font-size: 14px;
			font-weight: 500;
			color: #11142d;
			line-height: 19px;
		}
	}
	&-share {
		height: 30px;
		background: #e6f5ff;
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: 500;
		color: #39b0fc;
		padding: 0 18px;
		margin-right: 58px;
		&__icon {
			width: 16px;
			height: 16px;
			margin-right: 6px;
		}
	}
}
</style>
