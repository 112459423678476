export default {
    install(Vue, options) {
        Vue.filter('formatDate', (date, fmt = 'YYYY-mm-dd HH:MM') => {
            // YYYY-mm-dd HH:MM:SS
            if (!date) {
                return '';
            }
            if (
                Object.prototype.toString.call(date) == '[object String]' &&
                date.match('-')
            ) {
                date = date.replace(/\-/g, '/');
            }
            let ret;
            date = new Date(date);
            const opt = {
                'Y+': date.getFullYear().toString(), // 年
                'm+': (date.getMonth() + 1).toString(), // 月
                'd+': date.getDate().toString(), // 日
                'H+': date.getHours().toString(), // 时
                'M+': date.getMinutes().toString(), // 分
                'S+': date.getSeconds().toString() // 秒
                // 有其他格式化字符需求可以继续添加，必须转化成字符串
            };
            for (const k in opt) {
                ret = new RegExp(`(${k})`).exec(fmt);
                if (ret) {
                    fmt = fmt.replace(
                        ret[1],
                        ret[1].length == 1
                            ? opt[k]
                            : padZero(opt[k], ret[1].length)
                    );
                }
            }
            return fmt;
            function padZero(target, length) {
                const t_length = target.length;
                if (t_length === length) {
                    return target;
                }
                const temp = '0000000000';
                if (t_length < length) {
                    return `${temp.substr(0, length - t_length)}${target}`;
                }
                return target;
            }
        });

        Vue.filter('formatSize', (value) => {
            if (value < 0 || null == value || value == '') {
                return '0B';
            }
            var unitArr = new Array(
                'B',
                'KB',
                'MB',
                'GB',
                'TB',
                'PB',
                'EB',
                'ZB',
                'YB'
            );
            var index = 0;
            var srcsize = parseFloat(value);
            index = Math.floor(Math.log(srcsize) / Math.log(1024));
            var size = srcsize / Math.pow(1024, index);
            size = size.toFixed(2); //保留的小数位数
            return size + unitArr[index];
        });
    }
};
export const formatDate = (date, fmt) => {};
