<template>
	<div 
		class="t-button hover" 
		:class="[type, isPhone ? 'phone' : '', large ? 'large' : '', size, nr ? 'nr' : '']" 
		@click="click"
		:style="{width: `${width}px`}"
	>
		<slot></slot>
	</div>
</template>

<script type="text/javascript">
import { mapState } from 'vuex';
export default {
	data() {
		return {

		}
	},
	props: {
		type: String,
		size: String,
		large: Boolean,
		nr: Boolean,
		width: {
			type: [String, Number],
			default: 180
		}
	},
	computed: {
		...mapState(['isPhone'])
	},
	methods: {
		click() {
			this.$emit('click')
		}
	}
}
</script>

<style lang="less">
.t-button {
	width: 180px;
	height: 52px;
	text-align: center;
	font-size:18px;
	color: white;
	line-height: 52px;
	background: #39B0FC;
	border-radius: 12px;
	box-sizing: border-box;
	user-select: none;
	margin-right: 28px;
	&.nr {
		margin-right: 0;
	}
	&.plain {
		background: white;
		color: #39B0FC;
		border: 2px solid #39B0FC;
		&:hover {
			background: #E7F5FE;
		}
	}
	&.gray {
		color: #7F838C;
		background: white;
		border: 1px solid #C9CDD4;
	}
	&.min {
		width: 70px !important;
		height: 38px;
		line-height: 38px;
		font-size: 16px;
		border-radius: 8px;
		margin-right: 24px;
	}
}
</style>