
import Vue from 'vue'
import Vuex, { Playload, Store} from 'vuex'
import VuePersist from 'vuex-persist'
import {
    getCatalogueTree,
    getDefaultCatalogue,
    
} from '@/api/official'
import {
    getDiskSize,
    checkDiskIsOpen,
    openDisk,
} from '@/api/mine'

Vue.use(Vuex)

/**
 * store数据本地持久化
 */
const vuexLocal = new VuePersist({
    key: 'vuexbase',
    storage: window.localStorage,
    reducer: (state) => {
        return {    
            token: state.token,
            showMoveTips: state.showMoveTips,
            showTwoMoveTips: state.showTwoMoveTips,
            userInfo: state.userInfo,
            needShowUploadTips: state.needShowUploadTips,
            previewFile: state.previewFile,
            gioLevelData: state.gioLevelData,
            prevPathName: state.prevPathName,
            correctData: state.correctData,
            
        }
    }
})

var sUserAgent = navigator.userAgent;

const isWeixin = () => {
    var ua = window.navigator.userAgent.toLowerCase()

    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true
    } else {
        return false
    }
}

export default new Vuex.Store({
    state: {
        isWeixin: isWeixin(),
        isPhone: sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1,
        token: '', // token
        userInfo: {},
        gradeList: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '七年级', '八年级', '九年级'],
        showMoveTips: true,
        showTwoMoveTips: true,
        needShowUploadTips: true,
        openSlide: true,
        treeData: [],
        treeDefault: [],
        previewFile: {},
        disk: {}, // 硬盘大小
        gioLevelData: {},
        prevPathName: '',
        correctData: {}
        
    },
    mutations: {
        
        updatePrevPathName(state, prevPathName) {
            state.prevPathName = prevPathName
        },
        updateCorrectData(state, correctData) {
            state.correctData = correctData
        },
        savePreviewFile(state, previewFile) {
            state.previewFile = previewFile
        },
        updateOpenSlide(state, openSlide) {
            state.openSlide = !state.openSlide
        },
        updateMoveTips(state, showMoveTips) {
            state.showMoveTips = showMoveTips
        },
        updateMoveTwoTips(state, showMoveTips) {
            state.showTwoMoveTips = showMoveTips
        },
        updateUploadTips(state, needShowUploadTips) {
            state.needShowUploadTips = needShowUploadTips
        },
        updateToken(state, token) {
            state.token = token
        },
        updateUserInfo(state, data) {
            state.userInfo = data
        },
        updateTree(state, data) {
            state.treeData = data
        },
        updateDefaultTree(state, data) {
            state.treeDefault = data
        },
        updateDiskSize(state, data) {
            state.disk = data
        },
        updateGioLevelData(state, data) {
            state.gioLevelData = data
        },
        
        
    },
    actions: {
        async fetchTree(store) {
            await getDefaultCatalogue().then(res => {
                store.commit('updateDefaultTree', res.data)
            })
            await getCatalogueTree().then(res => {
                store.commit('updateTree', res.data)
            })
        },
        
        async fetchDiskSize(store) {
            const {ifOpened} = await checkDiskIsOpen().then(res => res.data)
            console.log(ifOpened)
            if (!ifOpened) {
                await openDisk()
            }

            await getDiskSize().then(res => {
                const {capacity} = res.data
                store.commit('updateDiskSize', capacity)
                
            })
        }
    },
    plugins: [vuexLocal.plugin]
})

