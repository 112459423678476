<template>
	<div class="layout">
		<navigation ></navigation>
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
		

	</div>
</template>

<script type="text/javascript">
import navigation from '@/components/navigation';
import tabbar from '@/components/tabbar';

import { mapState } from 'vuex';
export default {
	data() {
		return {};
	},
	watch: {
		token: {
			handler() {
				if (!this.token) {
					this.$router.push('/login');
				}
			},
			immediate: true
		}
	},
	computed: {
		...mapState(['token', 'isPhone', 'isWeixin'])
	},
	components: {
		navigation,
		tabbar
	},
	mounted() {}
};
</script>

<style lang="less">
.layout {
	height: 100%;
	background: #f8fafb;
}

</style>
