<template>
    <div id="app">
    	<router-view />

    	
    </div>
</template>


<script type="text/javascript">
import { mapState } from 'vuex';
export default {
	data() {
		return {

		}
	},
	computed: {
		...mapState([ 'isPhone', 'isWeixin'])
	},
}
</script>

<style lang="less">
#app {
    min-height: 100%;
    background: #f8fafb;

}
#downloadBox {
	width: 300px;
	height: 90px;
	position: fixed;
	z-index: 3330;
	right: 20px;
	bottom: 20px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	background: white;
	border-radius: 6px;
	.downloadBox-title {
		color: #11142d;
		font-size: 14px;
		padding: 5px 10px 0;

		line-height: 14px;
	}
}

</style>
