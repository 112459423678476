import Axios from '../common/http.js';



/**
 * 判断网盘是否开通 
 */
export const checkDiskIsOpen = (params) => {
	return Axios.get('/go/api/teacher/netDisk/ifOpened', {params});
};
/**
 * 开通网盘 
 */
export const openDisk = (params) => {
	return Axios.get('/go/api/teacher/netDisk/open', {params});
};
/**
 * 2. 可用容量信息 
 */
export const getDiskSize = (params) => {
	return Axios.get('/go/api/teacher/netDisk/info', {params});
};


/**
 * 我的网盘 
 */
export const myResourceList = (data) => {
	return Axios.post('/go/api/teacher/netDisk/resource/my', data);
};


/**
 * 删除资源 
 */
export const deleteResource = (data) => {
	return Axios.post('/go/api/teacher/netDisk/resource/delete', data);
};

/**
 * 修改文件名 
 */
export const updateResourceName = (data) => {
	return Axios.post('/go/api/teacher/netDisk/resource/update', data);
};

/**
 * 下载 
 */
export const downloadResourceFile = (params) => {
	return Axios.get('/go/api/teacher/netDisk/resource/download', {params});
};

/**
 * 新建文件夹
 */
export const createDirectoryApi = (data) => {
	return Axios.post('/go/api/teacher/netDisk/resource/createDirectory', data);
};
/**
 * 移动文件夹
 */
export const directoryMoveApi = (data) => {
	return Axios.post('/go/api/teacher/netDisk/resource/move', data);
};

/**
 * 上传文件
 */
export const uploadFile = (data, options) => {
	return Axios.post('/go/api/teacher/resource/upload', data, options);
};
/**
 * 保存文件
 */
export const diySaveFileApi = (data, options) => {
	return Axios.post('/go/api/teacher/netDisk/resource/diysave', data, options);
};

