
export default {
    data() {
        return {
			
			
        }
    },
	computed: {

	},
	methods: {
		
		get (source, path, defaultValue = '') {
			
			if (!path) return
			// a[3].b -> a.3.b
			const paths = path.replace(/\[(\d+)\]/g, '.$1').split('.')
			let result = source
			for (const p of paths) {
				result = Object(result)[p]
				if (result === undefined) {
					return defaultValue
				}
			}
			return result
		},
		
		
	},
	
}
