import Axios from '../common/http.js';

/**
 * 获取默认目录
 */
export const getDefaultCatalogue = (params) => {
	return Axios.get('/go/api/teacher/resource/getDefaultCatalogue', {params});
};

/**
 * 获取目录树
 */
export const getCatalogueTree = (params) => {
	return Axios.get('/go/api/teacher/resource/getCatalogueTree', {params});
};

/**
 * 获取后三级默认
 */
export const getLowerDefault = (params) => {
	return Axios.get('/go/api/teacher/resource/getLowerDefaultCatalogue', {params});
};

/**
 * 获取标签列表
 */
export const getTagTypeList = (params) => {
	return Axios.get('/go/api/teacher/resource/getTagTypeList', {params});
};

/**
 * 获取资源列表
 */
export const getResourceList = (params) => {
	return Axios.get('/go/api/teacher/resource/getResourceList', {params});
};
/**
 * 点赞资源列表
 */
export const likeResource = (params) => {
	return Axios.get('/go/api/teacher/resource/like', {params});
};

/**
 * 备课资源保存到个人网盘 
 */
export const saveResources = (data) => {
	return Axios.post('/go/api/teacher/netDisk/resource/save', data);
};
/**
 * 增加预览次数 
 */
export const addLookCount = (params) => {
	return Axios.get('/go/api/teacher/resource/addLookCount', {params});
};
